import { cleanObject } from './Object-functions';

interface DecodedPayload {
  signature?: string;
  payload?: string;
}

export const decodePayload = (sr: string): DecodedPayload => ({
  signature: sr?.split('.')?.[0],
  payload: sr?.split('.')?.[1],
});

/**
 * Removes excess backslashes from Unicode characters from server
 */
const unescapeString = (str) => str.replace('\\\\u', '\\u');

/**
 * Decodes base64 payload string and removes escape characters around Unicode
 * characters
 * @param {String} signedRequest The base64 encoded string
 * @returns {Object} The payload object
 */
export const escapeParsedPayload = (payload: string) =>
  JSON.parse(unescapeString(atob(payload)));

/**
 * Extracts Omni GUID for value from omni initial adta values
 *
 * @param {Array<Object>} omniValues The customer fields object
 * @returns {Object} Object formatted to API payload standard
 */
const extractOmniName = (value, omniValues) =>
  omniValues[omniValues.findIndex((entry) => entry.key === value)].value || '';

/**
 * Formats the date fo birth date into a string with format dd/mm/yyyy
 *
 * @param {String} dateOfBirth The timestamp string
 * @returns {String} The resultant date string
 */
const formatDateOfBirth = (dateOfBirth) => {
  const date = new Date(dateOfBirth);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${day}/${month}/${year}`;
};

/**
 * Extracts address details from the field values
 *
 * @param {Object} formData The form data
 * @param {Object} omniData The Omni initial data
 * @returns {Object} Object formatted to API payload standard
 */
const extractAddressDetails = (formData) => {
  // extracts the relevant info from the details object
  const form = {
    flat:
      formData?.address?.addressDetails?.subBuildingNumber ||
      formData?.address?.addressDetails?.subBuildingName,
    houseNumber: formData?.address?.addressDetails?.buildingNumber,
    houseName: formData?.address?.addressDetails?.buildingName,
    street: formData?.address?.addressDetails?.thoroughfare,
    city: formData?.address?.city,
    postCode: formData?.address?.postalCode,
    yearsAtAddress: formData.yearsAtAddress,
  };

  // extract the keys of the cleaned object
  const keys = Object.keys(cleanObject(form));

  // if any of the three defined fields or one of `flat`, `house_number` or
  // `house_name` (of which at least once must be provided) isn't present,
  // return null
  if (
    !keys.includes('city') ||
    !keys.includes('postCode') ||
    !keys.includes('yearsAtAddress') ||
    !keys.some(
      (key) => ['flat', 'houseNumber', 'houseName'].indexOf(key) !== -1,
    )
  )
    return null;

  // cleans the object and returns it
  return cleanObject(form);
};

/**
 * Extracts customer details from the field values
 *
 * @param {Object} formData The form data
 * @returns {Object} Object formatted to API payload standard
 */
const extractCustomerDetails = (formData) => {
  // determines how many addresses are needed to accumulate 3 years worth of
  // address details
  const numberOfAddreses = formData.addresses.length;

  // extracts the info from the details object
  const form = {
    email: formData.email,
    title: formData.title,
    firstName: formData.firstName,
    middleName: formData.middleName,
    lastName: formData.lastName,
    maidenName: formData.maidenName,
    gender: formData.gender,
    dateOfBirth: formatDateOfBirth(formData.birthday),
    telephone: formData.phoneNumber.replace(/\s/, ''),
    mobile: formData.mobile.replace(/\s/, ''),
    maritalStatus: formData.maritalStatus,
    numberOfDependants: parseInt(formData.numberOfDependents, 10),
    residentialStatus: formData.residentialStatus,
    monthlyRentAmount: formData.monthlyRentAmount
      ? parseInt(formData.monthlyRentAmount, 10)
      : null,
    currentAddress: extractAddressDetails(formData.addresses[0]),
    goodsDeliveryAddress: extractAddressDetails(formData.addresses[0]),
    // optionally add `previous_address_one` if at least 2 addresses are needed
    ...(numberOfAddreses >= 2 && {
      previousAddressOne: extractAddressDetails(formData.addresses[1]),
    }),
    // optionally add `previous_address_two` if 3 addresses are needed
    ...(numberOfAddreses === 3 && {
      previousAddressTwo: extractAddressDetails(formData.addresses[2]),
    }),
  };

  return cleanObject(form);
};

/**
 * Returnss the required fields dependent on the employment type
 *
 * @param {Object} formData The form data
 * @param {Object} omniData The Omni initial data
 * @returns {Object} Object formatted to API payload standard
 */
const extractSpecificEmploymentDetails = (formData, omniData) => {
  const status = extractOmniName(
    formData.employmentStatus,
    omniData.employmentStatuses,
  );

  // If retired, return empty object
  if (status.toLowerCase().includes('retired')) {
    return {};
  }

  // If not employed, extract source of income
  if (status.toLowerCase().includes('not')) {
    return {
      sourceOfIncome: formData.sourceOfIncome,
    };
  }

  // if self employed, extract self employed fields
  if (status.toLowerCase().includes('self')) {
    return {
      typeOfBusiness: formData.typeOfBusiness,
      businessName: formData.nameOfBusiness,
      yearsEmployed: formData.yearsSelfEmployed,
    };
  }

  // If employed, extract employer details
  return {
    mainOccupation: formData.mainOccupation,
    employerName: formData.employerName,
    yearsEmployed: formData.yearsEmployed,
  };
};

/**
 * Extracts the employment information from the employemnt details field values
 *
 * @param {Object} formData The form data
 * @param {Object} omniData The Omni initial data
 * @returns {Object} Object formatted to API payload standard
 */
const extractEmploymentDetails = (formData, omniData) => {
  const employmentDetails = extractSpecificEmploymentDetails(
    formData,
    omniData,
  );

  return {
    ...employmentDetails,
    grossAnnualIncome: formData.grossAnnualIncome,
    householdAnnualIncome: formData.householdAnnualIncome,
    employmentStatus: formData.employmentStatus,
  };
};

/**
 * Extracts the banking information from the bank details field values
 *
 * @param {Object} formData The form data
 * @returns {Object} Object formatted to API payload standard
 */
const extractBankDetails = (formData) => ({
  sortCode: formData.bankSortCode,
  accountNumber: formData.accountNumber,
});

/**
 * Extracts the marketing preferences information from the field values
 *
 * @returns {Object} Object formatted to API payload standard
 */
const extractMarketingPreferences = () => ({
  receiveInfoViaPost: false,
  receiveInfoViaSms: false,
  receiveInfoViaEmail: false,
  receiveInfoViaPhone: false,
  agreePersonalDetailsToBeDisclosed: true,
});

/**
 * Extracts the finance application data from the field values, formats the data
 * into an object returned to the API standard
 *
 * @param {Object} formData The form data
 * @param {Object} omniData The Omni initial data
 * @param {Object} payloadData The signed request payload data
 * @returns {Object} Object formatted to API payload standard
 */
const extractData = (formData, omniData, payloadData) => ({
  acceptAllTermsAndConditions: formData.acceptedTerms,
  reference: payloadData.reference,
  price: payloadData.price,
  description: payloadData.descriptionOfGoods,
  ipAddress: formData.acceptedTermsIP,
  customerDetails: extractCustomerDetails(formData),
  employmentDetails: extractEmploymentDetails(formData, omniData),
  bankDetails: extractBankDetails(formData),
  marketingPreferences: extractMarketingPreferences(),
  rate: payloadData.enabledRates[0].toLowerCase(),
  deposit: Math.ceil((payloadData.price * payloadData.deposit) / 100),
});

/**
 * Builds the payload for the Omni finance service from the form values
 *
 * @param {Object} formData The form data
 * @param {Object} omniInitialData The Omni initial data
 * @param {Object} signedRequestPayload The signed request payload data
 * @returns {Object} Object formatted to API payload standard
 */
export const formatOmniFormData = ({
  formData,
  omniInitialData,
  signedRequestPayload,
}) => {
  // Format the form data into the Omni service expected format
  const data = extractData(formData, omniInitialData, signedRequestPayload);
  // Return the payload
  return {
    enterprise: {
      id: signedRequestPayload.enterpriseId,
    },
    data,
  };
};

/**
 * Identities the user in highlight
 *
 * @param {Object} H Highlight object
 * @param {Object} data The data from the signed request
 */
export const highlightIdentify = (H, data) => {
  const identifier = data.email;

  const metadata = cleanObject({
    from_enterprise_id: data?.enterpriseId || undefined,
    reference: data?.reference || undefined,
    customer_first_name: data?.firstName || undefined,
    customer_last_name: data?.lastName || undefined,
    customer_mobile: data?.mobile || undefined,
    description: data?.descriptionOfGoods || undefined,
    deposit: data?.deposit || undefined,
    price: data?.price || undefined,
  });

  H.identify(identifier, metadata);
};

const mapRates = (rates) =>
  rates.map((rate) => {
    if (rate.includes('1190'))
      return rate.replace('1190', '1690').toUpperCase();
    if (rate.includes('1390'))
      return rate.replace('1390', '1690').toUpperCase();
    if (rate.includes('1590'))
      return rate.replace('1590', '1690').toUpperCase();
    return rate.toUpperCase();
  });

export const formatPayload = (payload) => {
  const {
    deposit: inputDeposit,
    enabled_rates: enabledRates,
    price,
    ...rest
  } = payload;
  const deposit = inputDeposit ? inputDeposit * 100 : null;
  const rates = enabledRates ? mapRates(enabledRates) : null;
  return cleanObject({
    ...rest,
    price: Math.round(price),
    ...(deposit ? { deposit } : {}),
    ...(rates ? { enabled_rates: rates } : {}),
  });
};

export const parseError = (error) => {
  if (!error?.message) return 'Unknown error ocurred';
  return error?.message?.split(',').reduce(
    (object, part) => {
      const components = part?.split('=');
      if (components?.length !== 2) return object;
      if (components[0]?.replace(/ /g, '') === 'code')
        return {
          ...object,
          [components[0]?.replace(/ /g, '')]: components[1],
        };
      return {
        ...object,
        [components[0]?.replace(/ /g, '')]: String(components[1]),
      };
    },
    {
      error: 'An unknown error occurred',
    },
  );
};
